
import { Vue, Component, Watch } from "vue-property-decorator";
import Pessoa from "@/core/models/cadastros/Pessoa";
import { PessoaService } from "@/core/services/cadastros/PessoaService";
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import { TableToExcel } from "@/assets/scripts/helper";
 
@Component
export default class ListaCliente extends mixins(Vue, ListPage) {
  item = new Pessoa();
  filter = new Filtro();
  service = new PessoaService();
  pessoa = new Pessoa(); 
  pessoas = [];
  
  loading: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0; 
  sheet:boolean = false;
  
  filterOptions = [
    { value: 'isCliente', label: 'Cliente' },
    { value: 'isProprietario', label: 'Proprietário' },
    { value: 'isFornecedor', label: 'Fornecedor' },
    { value: 'isCorretor', label: 'Corretor' },
    { value: 'isSeguradora', label: 'Seguradora' }
  ];

  ResetFiltro(){
    this.filter = {id:0, nome:'', cnpjCpf:'', rg:'', dataNascimento:'', email:'', selectedOptions:[]}
  }
 
  options: any = {
    itemsPerPage: 15
  };
   breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Lista de Pessoas', disabled: true, href: '/financeiro/painel/despesas'}
  ]

  headers: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Nome", value: "nome" },
    { text: "CPF/CNPJ", value: "cnpjCpf" },
    { text: "E-mail", value: "email" },
    { text: "Telefone", value: "telefone" },
  ];
 
  @Watch("options", { deep: true })
  Atualizar() { 
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;
    
    this.service.Listar(page,itemsPerPage,sortBy,sortDesc,search, columns, this.filter, '', 'id, nome, cnpjCpf, email, telefone', '').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    ).finally(() => (this.loading = false));
  }
  
  Consultar() {
    this.options.page = 1;

    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true
    this.service.Listar(page,itemsPerPage,sortBy,sortDesc,search, columns, this.filter, '', 'id, nome, cnpjCpf, email, telefone', '').then(
    
    (res) => {  
      this.lista = res.data.items
      this.totalLista = res.data.count;
      this.loading = false; 
     },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }); 
    this.sheet = false;
  }
   
  Novo() {
    this.item = new Pessoa();
    this.dialogCadastro = true;
  }
  
  Excluir(item) { 
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não", 
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.Excluir(item.id).then(
          res => {
            if (res.status == 200) {
              return res;
            }
          },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 500 ? "warning" : "error");
          }
        }); 
      }, 
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
    }).then((result:any) => {
      if (result.value.status == 200) {
        this.$swal("Aviso",result.value.data, "success");
        this.Atualizar();
      }else{
         this.$swal("Aviso","Não é possível excluir esse registro, essa pessoa tem vínculo no sistema.", "error");
      }
    }); 
  }  
 
  Editar(item){
    this.service.ObterPorId(item.id, 'Documentos, Contatos, Familiares').then(
      res => { 
        this.item = new Pessoa(res.data);
        
         this.item.dataExpedicao =  new Date(res.data.dataExpedicao).yyyymmdd();
         this.item.dataNascimento = new Date(res.data.dataNascimento).yyyymmdd();
         this.item.conjuge.dataNascimento = new Date(res.data.conjuge.dataNascimento).yyyymmdd();
         this.item.juridicaResponsavel.dataExpedicao = new Date(res.data.juridicaResponsavel.dataExpedicao).yyyymmdd();
         this.item.juridicaResponsavel.dataNascimento = new Date(res.data.juridicaResponsavel.dataNascimento).yyyymmdd();
        
        if (Array.isArray(this.item.familiares)) {
          const sortedFamiliares = [...this.item.familiares].sort((a, b) => a.id - b.id);
          this.item.familiares = sortedFamiliares;
        }
        this.dialogCadastro = true;
    },
     (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }
    ); 
  } 

  ObterRelatorio(item){
     let routeLink = this.$router.resolve({name: 'relPessoa',
      query:{
        pessoaId: item.id ? item.id.toString() : '',
       }
    });

    window.open(routeLink.href, '_blank');
  }

  ExportarExcel(tabela: any, worksheet?: string) {
    TableToExcel(tabela, worksheet);
  }

  mounted(){
    new PessoaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'id, Nome, CnpjCpf,Rg, Email', '').then(
      res => {
        this.pessoas = res.data.items;
      }
    )
  } 
}

class Filtro {
  public id:number = 0;
  public nome:string = '';
  public cnpjCpf: string ='';
  public rg: string ='';
  public dataNascimento:string = '';
  public email:string = '';
  selectedOptions = [];
}
